
.modal {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    width:400px;
    z-index: 200;
}

.popup{
    width: 400px;
    height: 400px;
    display: block;
}
.container{ 
    display: flex; justify-content: space-between; align-items: center; height: 32px; width: 100%; background: #666; padding: 0px 5px;
}

.modal.hidden{ display: none; }

.today_close{color: white; font-weight: 400; font-family: dotum, '돋움';}
.btn_close{border: 1px solid #a9a9a9;
  background-color: #ededed;
  color: #333 !important; font-size: 13px;
  font-weight: 500;
  padding: 2px 4px; cursor: pointer;}