.background {width: 100%; height: 100%; position: absolute; top: 0px; left: 0px;}
.backgroundd {width: 100%; height: 100vh; background-image: url('../images/login_background.png'); background-repeat: no-repeat; background-position: center;  background-size: cover;  background-position: 25% 75%;}
.form {display: flex; text-align: center; background-color: white; position: absolute; top: 50%;left: 50%; transform: translate(-50%, -50%); height: auto; box-sizing: border-box;}
.left{width: 550px; height: 600px;}
.left img{width: 100%;}
.right{ width: 400px; height: 600px; padding: 74px 50px 80px; display: flex; flex-direction: column; justify-content: space-between; align-items: center;}
.input_box {margin-bottom: 20px; border-radius: 5px; border: 1px solid #C6C6C6; display: flex; align-items: center; padding: 5px 20px;}
.input_box img{width: 20px; height: 20px; display: flex; align-items: center;}
.input_box input{width: 100%; border: none; margin-left: 5px; padding: 13px; font-size: 15px; font-weight: 500; outline: none;}
.span { font-weight: 400; font-size: 14px; color: #333333;}
.h3 { display: block; font-weight: 500; font-size: 24px; color: #333333;}
.text{text-align: left; width: 100%;}
.logo{width: 200px;}

/* @media screen and (max-width: 800px) {
    .backgroundd { background-image: url('../images/seolnal_login_background_mobile2.png');  background-position: top;}
    .form{    width: 95%; top: 50%; left: 50%; transform: translate(-50%, -50%);}
} */

@media screen and (max-width: 980px) {
    /* .backgroundd { background-image: url('../images/seolnal_login_background_mobile2.png');  background-position: top;} */
    .form{ width: 95%;}
    .left {display: none;}
    .right {width: 100%;}
    .input_box_container {width: 100%;}
    .input_box{width: 100%;}
}
