.container{padding: 60px 0px; display: flex; flex-direction: row; justify-content: space-between;}
.left{display: flex; flex-direction: column; justify-content: space-between; width: 48%;}
.right{ width: 48%; height: fit-content; padding: 35px; display: flex; flex-direction: column; justify-content: space-between; background-color: #f5f5f5;}
.input {border-radius: 8px; width: 100%; height: 50px; font-size: 15px; border: 1px solid #dadada; padding: 0 4%;}
.input:focus {background-image: none; outline: none;}
.input::placeholder {color: #B1B1B1;}
.box {margin-bottom: 25px; }
.background {margin-bottom: 10px;}
.background::placeholder {color: #B1B1B1; background-size: 20px; background-image: url('../images/icons/search.png'); background-repeat: no-repeat; background-position: center;  background-position: 0% 46%; padding: 0 8%;}
.daum_container{position: relative;}
.close_img{cursor:pointer;position:absolute;right:0px;top:-1px;z-index:1}
.row {display: flex; gap: 5%;}
.span{ margin-bottom: 10px; font-size: 18px;display: block;}
.label{margin-bottom: 20px; background-color: #C8965A; border-radius: 10px; padding: 5px 16px; font-weight: 400; font-size: 15px; color: white; display: inline-block;}
.textarea{ resize: none; height: 120px; width: 100%; border-radius: 8px; font-size: 15px; border: 1px solid #dadada; padding: 4%;}
.textarea:focus {outline: none;}
.textarea::placeholder {color: #B1B1B1;}
.top{margin-bottom: 40px; background-color: #DFDFDF; padding: 13px 30px; border-radius: 10px; text-align: center; font-weight: 600; font-size: 20px;}
.column {display: flex; flex-direction: column; justify-content: space-between;}
.item_info {margin-bottom: 80px; display: flex; gap: 30px; justify-content: space-between; width: 100%;}
.img_box {width: 150px; height: 150px;}
.img_box img{width: 150px; height: 150px;}
.info_box {padding: 20px 0px; display: flex; flex-direction: column; justify-content: space-between; width: calc(100% - 120px);}


@media screen and (max-width: 800px) {
    .container{ flex-direction: column; }
    .left{ width: 100%;}
    .right{ width: 100%;}
 }