.row{display: flex; justify-content: space-between; width: 100%; margin: 15px 0px;}
.label{ font-weight: 600; width: 40%;
    text-align: left; color: rgb(100, 100, 100);}

.value{ font-weight: 400; width: 60%; text-align: right;}
.title {font-weight: 600; color: #b39466;  text-align: center; font-size: 24px; display: block; margin-bottom: 30px;}
.section2{ border: 2px solid #b39466; padding: 20px; width: 38%; margin-bottom: 50px;}
.button1{ background-color: #C2A476; color: white; margin-right: 20px; padding: 15px 50px; font-size: 18px;}
.button2{ border: 1px solid #C2A476; padding: 15px 38px; font-size: 18px;}
.img{ width: 150px; height: 150px; margin-right: 40px;}
.container{ display: flex;  justify-content: space-between; width: 100%;}
.section1{margin-bottom: 50px; border: 1px solid #b39466; width: 60%; display: flex; justify-content: space-between; align-items: center; padding: 20px 10%;}
.h2{ font-size: 35px; font-weight: 500; margin: 120px 0px 80px; text-align: center;}
.name{ font-size: 20px; font-weight: 500;}
.button_container{width: 100%; text-align: center; margin-bottom: 100px;}
.p{ color: #b39466; font-size: 18px; margin: 0px 0px 50px; }
.line{width: 50px; height: 1px; background-color: #C2A476; display: inline-block; margin: 30px 0px;}
.mypage{padding: 150px 0px; text-align: center;}
.title2{font-size: 30px; font-weight: 500;}

@media screen and (max-width: 800px) {
    .h2 { margin: 50px 0px; font-size: 24px;}
    .container{ flex-direction: column; }
    .section1{width: 100%;}
    .section2{width: 100%;}
    .button_container{}
    .button1{width: 50%; margin: 0px; font-size: 15px;}
    .button2{width: 50%; margin: 0px; font-size: 15px;}
 }