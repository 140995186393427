.order_info_wrapper{width: 1024px; margin: auto; padding: 70px 0px;}
.h2 {width: 100%; margin-bottom: 50px; text-align: center;}
.h3 {margin-bottom: 13px; font-size: 22px;}
.item_info_container{display: flex; justify-content: space-between; align-items: center; padding: 0px 30px;}
.text_box {display: flex; flex-direction: column; justify-content: space-between; padding: 0px 20px; text-align: left;}
.name{margin-top: 10px;}
.width1{width: 45%;}
.width2{width: 10%;}
.width3{width: 15%;}
.width4{width: 10%; padding: 0px; text-align: center;}
.button1{background-color: #C2A476; font-size: 15px; padding: 4px 12px; color: white;}


@media screen and (max-width: 1024px) {
    .order_info_wrapper{ width: 95%;}
    .button1{font-size: 13px;}
    .h3{font-size: 18px;}
    .item_info_container{ padding: 0px;}
    .width4{padding: 0px;}
}

@media screen and (max-width: 700px) {
    .item_info_container{flex-direction: column;}
    .text_box{padding: 0px; text-align:center}
    .width1{width: 20%;}
    .width2{width: 15%;}
    .width3{width: 20%;}
    .width4{width: 15%;}
}

@media screen and (max-width: 500px) {
    .width1{width: 35%;}
    .width2{width: 10%;}
    .width3{width: 15%;}
    .width4{width: 15%;}
    .spec{display: none;}
}