.container {margin-top:70px; margin-bottom:130px; display: flex; flex-direction: row; justify-content: space-between;}
.left{ width: 47%;}
.left > img{ max-width: 550px; width: 100%;}
.right{ width: 47%; display: flex; flex-direction: column; justify-content: space-between;}
.label{width: 10%; margin-right: 20%;}
.value{font-weight: 700;}
.border {border-bottom: 1px solid #BEBEBE; border-top: 1px solid #BEBEBE; padding: 35px 0px; }
.bottom {display: flex; justify-content: space-between; align-items: center;}
.bottom h3{font-size: 16px; font-weight: 600;}
.handler{display: flex; align-items: center; justify-content: space-between; padding: 3px 5px; border: 1px solid #D0D0D0; border-radius: 6px; width: 13%;}
.detail_img_section{ text-align: center; padding: 70px 50px 0px;}
.detail_img {max-width: 100%;}
.menu_form{position: sticky; top: 0px; display: flex; align-items: center;  margin: auto; width: 1280px; height: 70px; background: white;}
.menu{    
    border-bottom: 1px solid #333333;
    border-left: 1px solid #DADADA;
    border-top: 1px solid #DADADA;
    border-right: 1px solid #DADADA;
    border-radius: 10px 10px 0px 0px;

    display: flex;
    box-sizing: border-box;
    float: left;
    width: 25%;
    height: 100%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;}

.menu.active{     font-weight: 700;
    font-size: 18px !important;
    border-bottom: 0px;
    border-left: 1px solid #333333;
    border-top: 1px solid #333333;
    border-right: 1px solid #333333;
    border-radius: 10px 10px 0px 0px;}
.temp {border-bottom: 1px solid #333333; width: 50%;height: 100%;    box-sizing: border-box;}

.caption{color: black;
    font-size: 16px;
    font-weight: bold;padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;}
.table { 
    border-spacing: 0;
    border-collapse: collapse;   margin-bottom: 40px;
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;}
.th{padding: 10px 10px;
    text-align: left;
    border-bottom: 1px solid #CECECE;
    font-size: 12px;    padding-left: 20px;
    background: rgb(250, 250, 250);
    font-weight: inherit;
    color: black;}
.td{padding: 10px 10px;
    text-align: left;
    border-bottom: 1px solid #CECECE;
    border-left: 1px solid #CECECE;
    font-size: 12px;}
.table_container{width: 50%;  margin: auto;}



@media screen and (max-width: 1280px) {
   .menu_form{width: 100%;}
   .menu{ width: 80%; }
   .temp{ width: 20%;}
}

@media screen and (max-width: 800px) {
    .container{ flex-direction: column;}
    .left{ width: 100%;}
    .left > img{ max-width: 600px; width: 100%; text-align: center; margin: auto; display: block;}
    .right{ width: 100%; display: flex; flex-direction: column; justify-content: space-between; margin-top: 20px;}
    .border{ margin: 30px 0px; }
    .bottom{ margin-bottom: 30px;}
    .table_container{width: 100%;}
 }

@media screen and (max-width: 500px) {
    .menu_form{width: 100%;}
    .menu{ width: 100%; font-size: 15px;}
    .menu.active{ width: 100%; font-size: 15px !important;}
    .temp{ display: none;}
    .handler{width: 20%;}
 }