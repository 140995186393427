nav{ height: 100px; width: 100%; border-bottom: 1px solid #D9D9D9;}
/* .wrapper{ width: 1280px; height: 100%; margin: auto; display: flex; align-items: center; justify-content: space-between;} */
.container{height: 100%; display: flex; align-items: center; justify-content: space-between;}
.row{display: flex; align-items: center; color: #333333; text-decoration: none; font-size: 14px; font-weight: 400;}
.row svg{width: 25px; height: 25px;margin-right: 2px;}
.right{display: flex; align-items: center;}
.right .row:not(:nth-last-child(1)){margin-right: 20px;}
.point {margin-left: 5px;}
.img{width: 150px;}


@media screen and (max-width: 900px) {
    .right .row:not(:nth-last-child(1)){margin-right: 10px;}
    .row {font-size: 12px;}
    .row svg{width: 15px; height: 15px;margin-right: 2px;}
}

@media screen and (max-width: 900px) {
    .right .row:not(:nth-last-child(1)){margin-right: 5px;}
}