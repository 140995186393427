.footer { background-color: #EEEEEE; width: 100%; padding: 40px 0px 70px;}
.row{display: flex; flex-direction: row; justify-content: space-between;}
.company_logo{width: 20%; margin-right: 30px;}
.company_logo img{width: 100%; min-width: 130px; max-width: 180px; margin-right: 30px;}
.about{width: 55%;}
.about p {margin: 6px 0px 0px;}

.telephone{width: 25%; margin-left: 30px;}
.telephone p {margin: 6px 0px 0px;}
.telephone b {margin-top: 6px; display: block; font-size: 30px;}

@media screen and (max-width: 700px) {
    .row {flex-direction:column}
    .company_logo{width: 100%;margin-bottom: 20px;}
    .about{width: 100%; margin-bottom: 50px;}
    .telephone{width: 100%; margin-left:0px}
}