/* .mainbanner{object-fit:cover; position: relative; width: 100%; height: 500px; background-image: url('../images/mainbanner1.jpg'); background-repeat: no-repeat; background-position: center;  background-size: cover; background-position: 90%;} */
.mainbanner{object-fit:cover; position: relative; width: 100%; height: 500px; background-image: url('../images/background-none.jpg'); background-repeat: no-repeat; background-position: center;  background-size: cover; background-position: 90%;}
.item1 {position: absolute; bottom: -3%; left: 0px; width: 300px;}
.item2 {position: absolute; bottom: -3%; right: 0px; width: 300px;}
.text_box {position: absolute; top: 24%; left: 0px; width: 100%;}
.text_contents{margin-bottom: 66px; width: 650px; text-align: end; font-weight: 300;}
.h3 {margin: 0px 0px 2px 0px; font-size: 36px; font-weight: bold; color: #333333;}
.b{ font-size: 42px; color: #FA5268;}
.span { font-size: 36px; display: block;}
.span1 { font-size: 36px; display: block; font-weight: 500;}
.span2 { font-size: 20px; display: block; font-weight: 400; margin-top: 30px;}
.text_duration {font-size: 20px; color: #333333; font-weight: 500; margin-top: 20px;}
.text_min {font-size: 14px; color: #333333; font-weight: 500; margin-top: 10px; display: inline-block;}
.itemlist{display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 100px 34px; padding: 0px;}

@media screen and (max-width: 1000px) {
    .mainbanner{ background-image: url('../images/mainbanner1.jpg');}
    .item1 {display: none;}
    .item2 {display: none;}
}

@media screen and (max-width: 1480px) {
    .item1 {left: auto; right: 250px; width: 260px;}
    .item2 { width: 250px;}
}

@media screen and (max-width: 900px) {
    .mainbanner{ height : 400px}
    .itemlist{ grid-template-columns: 1fr 1fr; gap: 50px 34px;}
    .h3 {font-size : 26px}
    .span { font-size : 26px }
    .b {font-size: 32px;}
    .text_duration{font-size: 18px;}
    .text_contents{width: 450px;}
}

@media screen and (max-width: 700px) {
    .mainbanner{ background-position:85%}
}

@media screen and (max-width: 500px) {
    .mainbanner{ height : 400px; background-position:center}
    .itemlist{ grid-template-columns: 1fr; gap: 30px 34px;}
    .h3 {font-size : 20px}
    .span { font-size : 20px }
    .b {font-size: 28px;}
    .text_duration{font-size: 15px;}
    .text_contents{text-align: left; width: 100%;}
}