@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@200;300;400;500;600;700&display=swap');

html {height: 100%}
body{margin: 0px; font-family: 'Noto Sans KR', sans-serif;}
main {width: 100%; font-family: 'Noto Sans KR', sans-serif;}
.wrapper {width: 1280px; margin: auto;}
button{ border: none; background: none; outline: none; cursor: pointer;}
h1, h2, h3, h4, h5 {margin: 0px;}
h1, h2, h3, h4, h5, span, div {font-family: 'Noto Sans KR', sans-serif;}
li{list-style: none;}
section {margin-bottom: 100px;}
*{box-sizing: border-box; font-family: 'Noto Sans KR', sans-serif;}

table.order-info-table{border-top: 2px solid #000000; border-collapse : collapse; width: 100%;}
table.order-info-table thead{background-color: #F2F2F2;}
table.order-info-table thead th{color: #000000; font-size: 16px; padding: 10px 20px; text-align: center;}
table.order-info-table tbody{border-top: 1px solid #A6A6A6; border-bottom: 1px solid #A6A6A6;}
table.order-info-table tbody td {text-align: center;}
table.order-info-table tbody td:not(:last-child) {padding: 24px;}
table.order-info-table tbody td img{width: 150px; height: 150px;}

table.order-info-table.text-left{border-collapse: separate; border-spacing: 20px 30px; border-bottom: 1px solid #A6A6A6;}
table.order-info-table.text-left tbody {border-bottom: 1px solid #A6A6A6;}
table.order-info-table.text-left tbody td{text-align: left; padding: 0px; width: 80%;}
table.order-info-table.text-left tbody th{padding: 0px; text-align: left;}
table.order-info-table.text-left tbody tr{padding: 20px;}

/* table.order-info-table.ship-info {border-collapse: separate; border-spacing: 0 30px;} */
table.order-info-table.ship-info tbody {padding: 10px 0px;}
table.order-info-table.ship-info tbody td {padding: 0px;}
table.order-info-table.ship-info thead th{ padding: 10px 20px;}
table.order-info-table.ship-info tbody tr{line-height: 60px;}

.text-center{text-align: center;}

/* 모달창 공통 css */
.modal-border{ padding: 35px 20px; border: 1px solid #C2A476; text-align: center;}
.modal-border hr{ margin: 15px auto 18px; padding: 0px; width: 100px; border-top: 1px solid #C2A476;}
.modal-border h2 { font-weight: 500; margin: 0px; padding: 0px; color: #C2A476; font-size: 30px; text-align: center; }
.modal-border p{ margin-bottom: 40px; }
.modal-border button{ margin: 0px 10px; }

@media screen and (max-width: 1290px) {
    .wrapper {width: calc(100% - 20px); margin: auto;}
    section{ margin-bottom:80px }
}

@media screen and (max-width: 1024px) {
    section{ margin-bottom:50px }
    table.order-info-table thead th {padding: 10px 0px; font-size: 13px;}
    table.order-info-table tbody td{font-size: 13px;}
    table.order-info-table tbody td:not(:last-child){padding: 10px;}
    table.order-info-table tbody td img {width: 80px; height: 80px; }
}

@media screen and (max-width: 500px) {
    section{ margin-bottom:30px }
    table.order-info-table.text-left tbody th {width: 40%; font-size: 15px;}
    table.order-info-table.text-left tbody td {width: 60%;}
}

.hidden{display: none !important;}

.notfound {width: 100%; }
.notfound-wrapper {width:100%; max-width: 450px; margin: auto; margin-top: 100px;}
.notfound-wrapper h2{font-size: 22px;}
.notfound-wrapper p{margin-top: 30px; display: block; font-size: 15px;}